<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div class="flex-1 bg-white pa-24 radius-20">

			<div class="under-line-bbb pb-16 flex-row">
				<h3 class="flex-1 size-px-20">{{	program.title }}</h3>
				<h6>마피아 서비스의 공지사항을 확인하세요.</h6>
			</div>

			<ul class="mt-30">
				<li class="under-line mb-16 flex-row justify-space-between items-center">
					<h4 class="color-333">
						{{ notice_title }}
						<span class="font-weight-bold text-red" v-if="notice_new_fg === 'Y'">NEW</span>
					</h4>
					<div class="color-888 size-px-14">{{ notice_reg_date }}</div>
				</li>
				<li class="under-line mb-20 pb-20 color-333" :inner-html.prop="notice_contents | nl2br"></li>
				<div class="mt-30" style="display: flex; justify-content: end;">
					<button class="btn-inline radius-30 pa-10-20" @click="$router.back()">목록</button>
				</div>
			</ul>
		</div>

	</div>
</template>

<script>
import MypageSide from "@/view/Mypage/mypage-lnb";
export default {
	name: "bbs_detail"
	,
	components: {MypageSide},
	props: ['user']
	, data: function () {
		return {
			program: {
				name: '커뮤니티'
				, title: '공지사항'
				, type: 'mypage'
				, bg_contents: 'bg-gray01'
			}
			, notice_number : this.$route.query.notice_number
			, notice_title: this.$route.query.notice_title
			, notice_reg_date: this.$route.query.notice_reg_date
			, notice_new_fg: this.$route.query.notice_new_fg
			, notice_contents: ''
		}
	}
	, methods: {
		viewItem: async function () {

			try {
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_notice_info
					, data: {
						member_number: this.user.member_number
						, notice_number: this.notice_number
					}
					, type: true
				})
				if (result.success) {
					this.notice_contents = result.data.notice_contents
				} else {
					throw result.message
				}


			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
	}

	, created() {
		this.$emit('onLoad', this.program)
		this.viewItem()
	}
}
</script>

<style scoped>
.section_wrap {
	display: flex;
	flex-wrap: wrap;
}

h6 {
	line-height: 260%;
	font-weight: 400;
}

li.under-line {
	padding-bottom: 10px;
}

.title {
	border-bottom: 1px solid #b2b2b2;
	box-sizing: content-box;
	display: flex;
	align-items: center;
	gap: 10px;
}

.text-gray {
	color: var(--light-Gray03);
}

.text-red {
	color: var(--red);
}

.text-black {
	color: var(--black);
}

.detail_title {
	width: 35px;
	height: 35px;
	line-height: 200%;
	background-color: #e5e5e5;
	border-radius: 20px
}

.btn-inline {
	background-color: white;
	color: var(--blue02);
	border: 1px solid var(--blue02); /* Green */
}

.btn-inline:hover {
	background-color: var(--blue02) !important;
	color: white;
}


.pagination {
	display: inline-block;
}

.pagination a {
	color: black;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
	transition: background-color .3s;
	border: 1px solid #ddd;
	margin: 0 4px;
}

.pagination a.active {
	background-color: #4CAF50;
	color: white;
	border: 1px solid #4CAF50;
}

.pagination a:hover:not(.active) {
	background-color: #ddd;
}

</style>